#Machinery_div {
    opacity: 0;
}

#Machinery_div.is-inViewport {
    opacity: 1;
    transition: opacity 1.5s ease-in-out;
    transition-delay: .1s;
}

@media screen and (max-width: 767px){   
    #firstImage{      
        content:url("../../public/Home\(11\).webp");
        height: 300px;
    } 
    #thirdImage{      
        content:url("../../public/Haas\ VM\ 3.webp");
        height: 300px;
    } 
    #fourthImage{      
        content:url("../../public/20230422_114556.webp");
        height: 300px;
    } 
    #fifthImage{      
        content:url("../../public/ACCUTE\ X\ CNC\ EDM.webp");
        height: 300px;
    } 
    #sixthImage{      
        content:url("../../public/KENT\ 510\ SURFACE\ GRENDER.webp");
        height: 300px;
    }
    #seventhImage{      
        content:url("../../public/KG\ VMC.webp");
        height: 300px;
    }
    #eigthSlide{      
        content:url("../../public/KINGSTEN\ VMC.webp");
        height: 300px;
    }
    #ninthSlide{      
        content:url("../../public/LOKESH\ CNC.webp");
        height: 300px;
    }
    #tenthSlide{      
        content:url("../../public/MILACRON.webp");
        height: 300px;
    }
}

@media screen and (min-width: 768px) {      
    #firstImage{      
       content:url("../../public/Home\(11\).webp");
       height: 300px;
     } 
     #thirdImage{      
        content:url("../../public/Haas\ VM\ 3.webp");
        height: 300px;
    } 
    #fourthImage{      
        content:url("../../public/20230422_114556.webp");
        height: 300px;
    } 
    #fifthImage{      
        content:url("../../public/ACCUTE\ X\ CNC\ EDM.webp");
        height: 300px;
    }
    #sixthImage{      
        content:url("../../public/KENT\ 510\ SURFACE\ GRENDER.webp");
        height: 300px;
    }
    #seventhImage{      
        content:url("../../public/KG\ VMC.webp");
        height: 300px;
    }
    #eigthSlide{      
        content:url("../../public/KINGSTEN\ VMC.webp");
        height: 300px;
    }
    #ninthSlide{      
        content:url("../../public/LOKESH\ CNC.webp");
        height: 300px;
    }
    #tenthSlide{      
        content:url("../../public/MILACRON.webp");
        height: 300px;
    }
}   
@media (min-width: 992px) and (max-width : 1600px) {    
     #firstImage{         
        content:url("../../public/Home\(11\).webp");   
        height: 600px;
     } 
     #thirdImage{      
        content:url("../../public/HaasVM3resize.webp");
        height: 600px;
    } 
    #fourthImage{      
        content:url("../../public/20230422_114556.webp");
        height: 600px;
    } 
    #fifthImage{      
        content:url("../../public/ACCUTE\ X\ CNC\ EDM.webp");
        height: 600px;
    }
    #sixthImage{      
        content:url("../../public/KENT510SURFACEGRENDERresize.webp");
        height: 600px;
    }
    #seventhImage{      
        content:url("../../public/KG\ VMC.webp");
        height: 600px;
    }
    #eigthSlide{      
        content:url("../../public/KINGSTENVMCresize.webp");
        height: 600px;
    }
    #ninthSlide{      
        content:url("../../public/LOKESHCNCresize.webp");
        height: 600px;
    }
    #tenthSlide{      
        content:url("../../public/MILACRON.webp");
        height: 600px;

    }
}
@media (min-width: 1601px) {    
     #firstImage{         
        content:url("../../public/Home\(11\).webp");   
        height: 850px;
     } 
     #thirdImage{      
        content:url("../../public/HaasVM3resize.webp");
        height: 850px;
    } 
    #fourthImage{      
        content:url("../../public/20230422_114556.webp");
        height: 850px;
    } 
    #fifthImage{      
        content:url("../../public/ACCUTE\ X\ CNC\ EDM.webp");
        height: 850px;
    }
    #sixthImage{      
        content:url("../../public/KENT510SURFACEGRENDERresize.webp");
        height: 850px;
    }
    #seventhImage{      
        content:url("../../public/KG\ VMC.webp");
        height: 850px;
    }
    #eigthSlide{      
        content:url("../../public/KINGSTENVMCresize.webp");
        height: 850px;
    }
    #ninthSlide{      
        content:url("../../public/LOKESHCNCresize.webp");
        height: 850px;
    }
    #tenthSlide{      
        content:url("../../public/MILACRON.webp");
        height: 850px;

    }
}
