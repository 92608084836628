.productBtn{
    background-color: #6a6425;
    border: 2px solid  #6a6425;
    font-weight: 600;
    box-shadow: 0px 0px 8px 2px #ccc;
}
.productBtn:hover {
    background-color: #565124;
}
.rangeCard{
    box-shadow: 0px 0px 10px 3px #4d789e;
    border: 1px solid #4d789e;
    min-height: 480px;
    border-radius: 10px;
}
.rangeCard:hover {
    box-shadow: 0px 0px 16px 6px #4d789e;
}
.range-card-text{
    line-height: 1.3;
    -webkit-line-clamp: 3;
    overflow: hidden;
    max-height: 120px;
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: rgb(88, 88, 88) !important;
  }
  .range-card-text p{ 
    color: rgb(88, 88, 88) !important;
  }
  .rangeTitle{
    font-size: 17px;
    font-weight: 600;
    color: #6a6425 !important;
  }
  .rangeImg{
    height: 240px;
    object-fit: cover;
    object-position: center;
  }