p{
    color:black
}
.moldContent{
    transition: .3s ease-in-out;
    cursor: pointer;
}
/* .moldContent:hover{
    box-shadow: 0 13px 23px rgba(0, 0, 0, 0.326), 0 6px 10.39px rgba(0, 0, 0, 0.31);
} */
.productImg{
    height: 300px;
    object-fit: cover;
    object-position: center;
}
.productTitle{
    font-size: 19px;
    font-weight: 600;
}